import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import company1 from "../../../images/company1.jpg";
import company2 from "../../../images/company2.jpg";
import company3 from "../../../images/company3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataService from "../../../services/data.service";
import { toast } from "react-toastify";
import "../../../customCss/Review.css";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

function SingleProductSpecs(props) {
  const form = React.useRef();
  const [vendorChat, setVendorChat] = useState(false);
  const [showChatVendor, setshowChatVendor] = useState(false);
  const [newChat, setNewChat] = useState("");
  const [startChat, setStartChat] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState("");
  const [review, setReview] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [cartselect, setCartSelect] = useState("1-99");
  const [visibleItems, setVisibleItems] = useState(2);
  const [product_qty, setProduct_qty] = useState("");
  const [product_unit, setProduct_unit] = useState(props?.data?.unit);
  const [message, setMessage] = useState("");
  const [cartInput, setCartInput] = useState(1);
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [sort, setSort] = useState("desc");
  const [countryId, setCountryId] = useState("");
  const [gCart, setGCart] = useState(1)

  // const storedCountryId = localStorage.getItem("ipData");
  const localStorageCountryId = localStorage.getItem('ipData');

  // console.log(localStorageCountryId)
  // const customizationData = JSON.parse(props?.data?.customization);

  const handleViewMore = () => {
    setVisibleItems(props?.review?.length);
  };
  useEffect(() => {
    if (props?.data?.price_groups) {
      let cartMinVal = JSON.parse(props?.data?.price_groups);
      setCartInput(Number(cartMinVal[0]?.min));
      setGCart(Number(cartMinVal[0]?.min));
      setUnitPrice(Number(cartMinVal[0]?.mrp))
    }
  }, [props?.data])

  const addTocart = (event) => {
    var item = props.data;
    item.cart_qty = cartInput;
    item.price = unitPrice === 0 ? cartInput * props?.data?.mrp : unitPrice;
    props.addTocart(event, item);
    setCartInput(1);
    setCalculatedPrice(200);
  };
  function calculatePrice(cartInput) {
    for (const range of JSON.parse(props?.data?.price_groups)) {
      if (cartInput >= range.min && cartInput <= range.max) {
        return range.mrp;
      }
    }
    return 0;
  }




  const getShippingAmount = (shippingRate, cartInput) => {
    if (!props?.data?.price_groups) {
      return null;
    }

    const priceGroups = JSON.parse(props.data.price_groups);

    for (const group of priceGroups) {
      const min = parseInt(group.min, 10);
      const max = parseInt(group.max, 10);

      if (cartInput >= min && cartInput <= max) {
        return shippingRate[`amount£{priceGroups.indexOf(group) + 1}`];
      }
    }

    return null;
  };



  const setMainInputValue = (e) => {
    if (e.target.value === "") {
      setCartInput(gCart);
    }
    else if (e.target.value < gCart) {
      toast.error(`Cart value should not be less than £{gCart}`)
      setCartInput(parseInt(gCart))
    }
    else {
      setCartInput(Number.parseInt(e.target.value));
      const numericValue = parseFloat(e.target.value);
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * e.target.value;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setUnitPrice(calculatePrice(numericValue));
          setCalculatedPrice(price * e.target.value);
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };


  const increaseNumber = () => {
    setCartInput(cartInput + 1);
    let x = Number.parseInt(document.getElementById("cart_input").value) + 1;
    const numericValue = x;
    if (!props?.data?.price_groups) {
      let unitP = props?.data?.offer_price * x;
      setCalculatedPrice(unitP);
      setUnitPrice(props?.data?.offer_price);
    } else {
      if (!isNaN(numericValue)) {
        const price = calculatePrice(numericValue);
        setCalculatedPrice(price * x);
        setUnitPrice(calculatePrice(numericValue));
      } else {
        setCalculatedPrice(0);
      }
    }
  };
  const decreaseNumber = () => {
    if (cartInput === 1 || cartInput === gCart) {
      toast.error(`Value should not less than £{gCart} !!`);
    } else {
      setCartInput(cartInput - 1);
      let x = Number.parseInt(document.getElementById("cart_input").value) - 1;
      const numericValue = x;
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * x;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setCalculatedPrice(price * x);
          setUnitPrice(calculatePrice(numericValue));
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const onChangeDescription = (e) => {
    const data = e.target.value;
    setDescription(data);
  };
  const onChangeEmail = (e) => {
    const data = e.target.value;
    setEmail(data);
  };
  const onChangeRating = (e) => {
    const data = e.target.value;
    setRating(data);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      name,
      email,
      rating,
      review: description,
      product_id: props.data.id,
    };
    DataService.addReview(data).then(
      () => {
        form.current.reset();
        toast.success("Review added successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const renderStars = (item) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < item) {
        stars.push(
          <span key={i} className="star filled">
            &#9733;
          </span>
        );
      } else {
        stars.push(
          <span key={i} className="star empty">
            &#9734;
          </span>
        );
      }
    }
    return stars;
  };

  const getPReviews = async (sorti) => {
    let ghj = sorti ? sorti : sort;
    await DataService.getProductReviews(props?.data?.id, ghj)
      .then((dw) => {
        setReview(dw.data.reviews);
        setReviewCount(dw.data.reviewsCount);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  useEffect(() => {
    getPReviews();
  }, [props?.data?.id]);

  useEffect(() => {
    if (props.VendorChat !== null) {
      setshowChatVendor(false);
      setVendorChat(true);
    } else {
      setshowChatVendor(true);
      setVendorChat(false);
    }
  }, [props.VendorChat]);
  // const getChat = async () => {
  //   await DataService.getChatWithVendor(props?.data?.User?.id)
  //     .then(async (data) => {
  //       setTimeout(function (){
  //         if(data?.data?.data !== null){
  //           console.log(data?.data?.data)
  //           setshowChatVendor(false)
  //           setVendorChat(true)
  //         }else{
  //           console.log(data?.data?.data)
  //           setshowChatVendor(true)
  //           setVendorChat(false)

  //         }
  //       },3000)
  //     })
  //     .catch((error) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //     });
  // };

  let user_id = JSON.parse(localStorage.getItem("user"));

  const sendNewMessage = (e) => {
    e.preventDefault();
    const data = {
      to_user_id: props?.data?.User?.id,
      message,
      product_qty,
      product_unit,
    };
    DataService.addChatWithVendor(data).then(
      () => {
        toast.success("message Sent!!");
        // getChat();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };


  const htmlContent = props?.data?.details;

  const renderHTMLAsText = () => {
    return { __html: htmlContent };
  }

  return (
    <div>
      <div className="container">
        <div className="main_product_page">
          <div className="card p-3 ">
            {/* <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  className="nav-link active nav_btn"
                  id="Product details"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"

                  aria-selected="true"
                >
                  Product details
                </button>

                <button
                  className="nav-link nav_btn_other"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Company profile
                </button>

                <button
                  className="nav-link nav_btn_other"
                  id="nav-buyer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-buyer"
                  type="button"
                  role="tab"
                  aria-controls="nav-buyer"
                  aria-selected="false"
                >
                  Contact Supplier
                </button>

                <button
                  className="nav-link nav_btn_other"
                  id="nav-customers-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-customers"
                  type="button"
                  role="tab"
                  aria-controls="nav-buyer"
                  aria-selected="false"
                >
                  Customers Reviews
                </button>
              </div>
            </nav> */}
            <div className="tab-content p-lg-3 tabs_content " id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="main_content">
                  <div className="main_content_title">
                    <h4>{props?.data?.name}</h4>
                  </div>
                  <div className="main_content_price d-md-flex justify-content-start">
                    {props?.data?.price_groups ? (
                      JSON.parse(props?.data?.price_groups).map((item, i) => {
                        return (
                          <>
                            <div className="range_catagory me-4">
                              <div className="price_range">
                                <p>
                                  <span>
                                    {" "}
                                    {item.min} - {item.max} {props?.data?.unit}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="range_price">
                                <p>
                                  <span>£ {item.mrp} per pieces</span>
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <div className="range_catagory me-4">
                          <div className="price_range">
                            <p>
                              <span></span>
                            </p>
                          </div>
                          <div className="range_price2">
                            <p>
                              <span>£ {props?.data?.cost_price}</span>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="product_buttons_sec">
                    <Link to={"/compare/" + props?.data?.id}>
                      <button className="compare_button">
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 2.99998C0 2.72384 0.223858 2.49998 0.5 2.49998H1C3.2021 2.49998 4.82702 3.74028 5.8737 4.9178C6.36445 5.46988 6.73942 6.02016 7 6.44996C7.26058 6.02016 7.63555 5.46988 8.1263 4.9178C9.17298 3.74028 10.7979 2.49998 13 2.49998V3.49998C11.2021 3.49998 9.82702 4.50969 8.8737 5.58216C8.40021 6.11485 8.04345 6.64962 7.80515 7.05176C7.69729 7.23377 7.61436 7.38748 7.55645 7.49998C7.61436 7.61249 7.69729 7.76619 7.80515 7.94821C8.04345 8.35035 8.40021 8.88511 8.8737 9.4178C9.82702 10.4903 11.2021 11.5 13 11.5V12.5C10.7979 12.5 9.17298 11.2597 8.1263 10.0822C7.63555 9.53008 7.26058 8.97981 7 8.55C6.73942 8.97981 6.36445 9.53008 5.8737 10.0822C4.82702 11.2597 3.2021 12.5 1 12.5H0.5C0.223858 12.5 0 12.2761 0 12C0 11.7238 0.223858 11.5 0.5 11.5H1C2.7979 11.5 4.17298 10.4903 5.1263 9.4178C5.59979 8.88511 5.95655 8.35035 6.19485 7.94821C6.30271 7.76619 6.38564 7.61249 6.44355 7.49998C6.38564 7.38748 6.30271 7.23377 6.19485 7.05176C5.95655 6.64962 5.59979 6.11485 5.1263 5.58216C4.17298 4.50969 2.7979 3.49998 1 3.49998H0.5C0.223858 3.49998 0 3.27612 0 2.99998Z"
                            fill="#F6F5FA"
                          />
                          <path
                            d="M13 4.96622V1.03374C13 0.821783 13.2472 0.705993 13.41 0.841687L15.7695 2.80793C15.8895 2.90788 15.8895 3.09209 15.7695 3.19204L13.41 5.15828C13.2472 5.29397 13 5.17818 13 4.96622Z"
                            fill="#F6F5FA"
                          />
                          <path
                            d="M13 13.9662V10.0337C13 9.82178 13.2472 9.70599 13.41 9.84169L15.7695 11.8079C15.8895 11.9079 15.8895 12.0921 15.7695 12.192L13.41 14.1583C13.2472 14.294 13 14.1782 13 13.9662Z"
                            fill="#F6F5FA"
                          />
                        </svg>
                        Compare
                      </button>
                    </Link>
                  </div> */}

                  <div className="main_content_attribute">
                    {/* <div className="benefits_sec">
                      <div className="benefit_sec_body row">
                        <div className="col-2 p-0">
                          <span>Benefits:</span>
                        </div>
                        <div className="col-8">
                          <span>
                            Quick refunds on orders under £ 500
                            <span>
                              {" "}
                              <Link to="">View more </Link>
                            </span>{" "}
                          </span>
                        </div>
                      </div>
                    </div> */}


                    {/* <div className="row">
                      <div className="col-lg-2">

                      </div>
                      <div className="col-lg-8">
                        <div className="int_sec">
                          <button
                            className="int_dec"
                            onClick={decreaseNumber}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            id="cart_input"
                            className="form-control"
                            value={cartInput}
                            onChange={setMainInputValue}
                          />

                          <button
                            className="int_inc"
                            onClick={increaseNumber}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-2">

                      </div>

                    </div> */}







                    {/* <div className="lead_time mt-2">
                      <div className="lead_time_body row">
                        <div className="col-md-2 p-0">
                          <span>Lead time </span>{" "}
                          <span>
                            <FontAwesomeIcon icon={faCircleInfo} />:
                          </span>
                        </div>
                        <div className="col-md-8 prdt-col">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <span>Quantity (pieces)</span>
                                </td>
                                {props?.data?.lead_time_days ? (
                                  JSON.parse(props?.data?.lead_time_days).map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <td>
                                            <span>{item.qty}</span>
                                          </td>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <td>
                                    <span> - </span>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>Lead time (Days)</td>
                                {props?.data?.lead_time_days ? (
                                  JSON.parse(props?.data?.lead_time_days).map(
                                    (item, i) => {
                                      return (
                                        <>
                                          <td>
                                            <span>{item.days}</span>
                                          </td>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <td>
                                    <span> - </span>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="customization mt-2">
                      <div className="customization_body row">
                        <div className="col-md-3">
                          <span>Customization:</span>
                        </div>
                        <div className="col-md-9">
                          {
                            !props?.data?.customization?.is_enabled ? <p>Not Offered</p> :
                              <>
                                <span>Customized logo quantity {props?.data?.customization?.logo_qty}</span>
                                <span>Customized packaging quantity {props?.data?.customization?.packaging_qty}</span>
                                <span>Customized graphic quantity{props?.data?.customization?.graphic_qty}</span>
                              </>

                          }

                        </div>
                      </div>
                    </div> */}


                    <div className="customization mt-2">
                      {/* <div className="customization_body row">
                        <div className="col-md-3">
                          <span>Description:</span>
                        </div>
                        <div className="col-md-9">
                          {props?.data?.description}
                        </div>
                      </div> */}
                    </div>
                    <div className="customization mt-2">
                      <div className="customization_body row">
                        <div className="col-md-3">
                          <span>Description:</span>
                        </div>
                        <div className="col-md-9">
                          <div dangerouslySetInnerHTML={renderHTMLAsText()} />
                        </div>
                      </div>
                    </div>
                    <div className="protection mt-3">
                      <div className="protection_body row">
                        <div className="col-md-3">
                          <span>Protection:</span>
                        </div>
                        <div className="col-md-9">
                          <span>
                            Trade Assurance{" "}
                            <span>Protects your DealIt.com orders</span>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="image_section_bottom">
                      {props?.data?.images?.length > 0
                        ? props?.data?.images?.map((item, i) => (
                          <img
                            src={
                              props.data.file_path
                                ? "https://api.dealitt.com/" + item.file_path
                                : ""
                            }
                            class="d-block w-100"
                            alt="..."
                          />
                        ))
                        : ""}
                    </div>

                    {/* <div className="ventore_btn">
                      <Link to={`/${props.data?.Store?.slug}`}>
                        Visit store
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="company-profile-tab">
                  <div className="company-head mb-4">
                    <h2>{props.data?.Store?.name}</h2>
                    <h3>Company Album</h3>
                  </div>
                  {/* <div className="company-images">
                    <img src={company1} alt="Profile-img" />
                    <img src={company2} alt="Profile-img" />
                    <img src={company3} alt="Profile-img" />
                  </div> */}


                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Company Overview</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Production Capacity</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">R&D Capacity</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="Factory-tab" data-bs-toggle="tab" data-bs-target="#Factory" type="button" role="tab" aria-controls="contact" aria-selected="false">Factory Size</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="Factory-tab" data-bs-toggle="tab" data-bs-target="#Factory" type="button" role="tab" aria-controls="contact" aria-selected="false">Quality Control</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="Factory-tab" data-bs-toggle="tab" data-bs-target="#Factory" type="button" role="tab" aria-controls="contact" aria-selected="false">Trade Capacity</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="profile-table">
                        <table className="table mt-2">

                          <tbody>
                            <tr>
                              <td className="table-title">Description</td>
                              <td className="new_table_dis">
                                <Link to="">{props.data?.Store?.description}</Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table mt-2">
                          <tbody>
                            <tr>
                              <td className="table-title">Operation Country</td>
                              <td>{props.data?.Store?.operation_country}</td>

                              <td className="table-title">Register Country</td>
                              <td>{props.data?.Store?.register_country}</td>
                            </tr>
                            <tr>
                              <td className="table-title">Nearest Port</td>
                              <td>
                                <Link to="">{props.data?.Store?.nearest_port}</Link>
                              </td>
                              <td className="table-title">Register Year</td>
                              <td> {props.data?.Store?.register_year}</td>
                            </tr>
                            <tr>
                              <td className="table-title">Started Exporting</td>
                              <td>{props.data?.Store?.started_exporting}</td>
                              <td className="table-title">Register State</td>
                              <td>{props.data?.Store?.register_state}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div className="profile-table">
                        <table className="table mt-2">

                          <tbody>
                            <tr>
                              <td className="table-title">Office Size</td>
                              <td>
                                <Link to="">{props.data?.Store?.office_size}</Link>
                              </td>
                              <td className="table-title">Operation City</td>
                              <td>{props.data?.Store?.operation_city}</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>

                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                      <div className="profile-table">
                        <div className="table mt-2">
                          <tbody>
                            <tr>
                              <td className="table-title">Opertaion Steet</td>
                              <td>
                                <Link to="">
                                  {props.data?.Store?.opertaion_steet}
                                </Link>
                              </td>
                              <td className="table-title">Rd Staff</td>
                              <td>{props.data?.Store?.rd_staff}</td>
                            </tr>
                          </tbody>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="Factory" role="tabpanel" aria-labelledby="Factory-tab">
                      <div className="capacity-table-head pt-4">
                        <h2>Factory information</h2>
                      </div>
                      <div className="capacity-table">
                        <table className="table mb-0">
                          <tbody>
                            <tr>
                              <td className="table-title">Factory Size</td>
                              <td>{props.data?.Store?.factory_size}</td>
                            </tr>
                            <tr>
                              <td className="table-title">
                                Factory Country/Region
                              </td>
                              <td>{props.data?.Store?.factory_location}</td>
                            </tr>
                            <tr>
                              <td className="table-title">Factory Size</td>
                              <td>1{props.data?.Store?.main_products}</td>
                            </tr>
                          </tbody>
                        </table>
                        {props.data?.Store?.website ? (
                          <div className="button_supp mt-3" style={{ textAlign: 'right' }}>
                            <Link to={props.data?.Store?.website} target="_blank">
                              <button>Visit Our Website</button>
                            </Link>
                          </div>
                        ) : ('')}

                      </div>

                    </div>
                  </div>

                  {/* <div className="profile-table">
                  <table className="table mt-2">
                    <tbody>
                      <tr>
                        <td className="table-title">Business type</td>
                        <td>
                          {props.data?.Store?.business_type
                            ? JSON.parse(props.data?.Store?.business_type).join(
                                ","
                              )
                            : ""}
                        </td>
                        <td className="table-title">Country / Region</td>
                        <td>Guangdong, China</td>
                      </tr>
                      <tr>
                        <td className="table-title">Main Products</td>
                        <td>
                          <Link to="">{props.data?.Store?.main_products}</Link>
                        </td>
                        <td className="table-title">Export percentage</td>

                        <td> {props.data?.Store?.export_percentage}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Operation Country</td>
                        <td>{props.data?.Store?.operation_country}</td>

                        <td className="table-title">Register Country</td>
                        <td>{props.data?.Store?.register_country}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Nearest Port</td>
                        <td>
                          <Link to="">{props.data?.Store?.nearest_port}</Link>
                        </td>
                        <td className="table-title">Register Year</td>
                        <td> {props.data?.Store?.register_year}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Started Exporting</td>
                        <td>{props.data?.Store?.started_exporting}</td>
                        <td className="table-title">Register State</td>
                        <td>{props.data?.Store?.register_state}</td>
                      </tr>
                      <tr>
                        <td className="table-title ">Description</td>
                        <td className="new_table_dis">
                          <Link to="">{props.data?.Store?.description}</Link>
                        </td>
                        <td className="table-title">Company Advantages</td>
                        <td>
                          {props.data?.Store?.company_advantages
                            ? JSON.parse(props.data?.Store?.company_advantages)
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-title">Office Size</td>
                        <td>
                          <Link to="">{props.data?.Store?.office_size}</Link>
                        </td>
                        <td className="table-title">Operation City</td>
                        <td>{props.data?.Store?.operation_city}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Opertaion Steet</td>
                        <td>
                          <Link to="">
                            {props.data?.Store?.opertaion_steet}
                          </Link>
                        </td>
                        <td className="table-title">Rd Staff</td>
                        <td>{props.data?.Store?.rd_staff}</td>
                      </tr>
                      <tr>
                        <td className="table-title">Qc Staff</td>
                        <td>
                          <Link to="">{props.data?.Store?.qc_staff}</Link>
                        </td>
                        <td className="table-title">Language Spoken</td>

                        <td>
                          {" "}
                          {props.data?.Store?.language_spoken
                            ? JSON.parse(
                              props.data?.Store?.language_spoken
                            ).join(",")
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-title">Manufacture Contract</td>
                        <td>
                          <Link to="">
                            {props.data?.Store?.manufacture_contract
                              ? JSON.parse(
                                props.data?.Store?.manufacture_contract
                              )
                              : ""}
                          </Link>
                        </td>
                        <td className="table-title">Operation Zip</td>
                        <td>{props.data?.Store?.operation_zip}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                  {/* <div className="capacity-part mt-4">
                  <div className="capacity-head">
                    <h2>PRODUCT CAPACITY</h2>
                  </div>
                  <div className="capacity-table-head pt-4">
                    <h2>Factory information</h2>
                  </div>
                  <div className="capacity-table">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="table-title">Factory Size</td>
                          <td>{props.data?.Store?.factory_size}</td>
                        </tr>
                        <tr>
                          <td className="table-title">
                            Factory Country/Region
                          </td>
                          <td>{props.data?.Store?.factory_location}</td>
                        </tr>
                        <tr>
                          <td className="table-title">Factory Size</td>
                          <td>1{props.data?.Store?.main_products}</td>
                        </tr>
                      </tbody>
                    </table>
                    {props.data?.Store?.website ? (
                      <div className="button_supp mt-3" style={{ textAlign: 'right' }}>
                        <Link to={props.data?.Store?.website} target="_blank">
                          <button>Visit Our Website</button>
                        </Link>
                      </div>
                    ) : ('')}

                  </div>
                </div> */}
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-buyer"
                role="tabpanel"
                aria-labelledby="nav-buyer-tab"
              >
                {vendorChat && (
                  <div className="button_supp">
                    <Link to="/chats">
                      <button>Chat Now</button>
                    </Link>
                  </div>
                )}
                {showChatVendor && (
                  <div className="capacity-form">
                    <div className="form-head mb-3">
                      <h2>Send your message to this supplier</h2>
                    </div>
                    <form onSubmit={sendNewMessage} ref={form}>
                      <div className="row align-items-baseline capacity-row">
                        <div className="col-md-2 text-md-end">
                          <div className="capacity-label">
                            <label>To:</label>
                          </div>
                        </div>
                        <div className="col-md-10 p-md-0">
                          <div className="capacity-field">
                            <h3>{props?.data?.Store?.legal_owner}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="row capacity-row mt-2">
                        <div className="col-md-2 text-md-end">
                          <div className="capacity-label">
                            <label>Message:</label>
                          </div>
                        </div>
                        <div className="col-md-8 p-md-0">
                          <div className="capacity-field">
                            <textarea
                              rows="6"
                              placeholder="Enter your inquiry details such as product name,color,size,MOQ,FOB,etc."
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            <div className="capacity-info">
                              <p>
                                Your message must be between 20-8000 characters
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row capacity-row mt-2">
                        <div className="col-md-2 text-md-end">
                          <div className="capacity-label">
                            <label>Quantity:</label>
                          </div>
                        </div>
                        <div className="col-md-8 p-md-0">
                          <div className="capacity-field-main d-md-flex">
                            <div className="capacity-field pe-2">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Please enter a number"
                                onChange={(e) => setProduct_qty(e.target.value)}
                              />
                            </div>
                            <div className="capacity-select">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setProduct_unit(e.target.value)}
                                value={props?.data?.unit}
                              >
                                <option value={props?.data?.unit}>
                                  {props?.data?.unit}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="capacity-btn">
                            <button type="submit" className="btn">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>

              <div
                className="tab-pane fade"
                id="nav-customers"
                role="tabpanel"
                aria-labelledby="nav-customers-tab"
              >
                <div className="capacity-form">
                  <div class="accordion-body">
                    <h2>Customer Reviews</h2>
                    <div className="reviews_select">
                      <div className="reviewsL">
                        <h4 className="review_newh5">
                          {reviewCount?.length > 0
                            ? reviewCount?.length + " REVIEWS FOR THIS PRODUCT"
                            : reviewCount?.length +
                            " REVIEW FOR THIS PRODUCT"}{" "}
                        </h4>
                      </div>
                      {review?.length > 1 ? (
                        <div className="reviewR">
                          <label>Sort By</label>
                          <select
                            onChange={(e) => {
                              {
                                setSort(e.target.value);
                              }
                              {
                                getPReviews(e.target.value);
                              }
                            }}
                            value={sort}
                          >
                            <option value="desc">Highest First</option>
                            <option value="asc">Lowest First</option>
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {review && review?.length > 0
                      ? review?.slice(0, visibleItems).map((item, i) => (
                        <div className="d-flex align-itmes-start gap-3">
                          {/*                         
              <div className="user-img">
                  <img src="../assets/img/slider/user-img.jpg" />
              </div> */}
                          <div className="review">
                            <div className="d-flex mt-3">
                              <div className="user-name">
                                <h5>
                                  <span>{item.name} </span>
                                  {renderStars(item?.rating)}
                                </h5>
                              </div>
                              <div className="review-date">
                                <h5 style={{ marginLeft: "7px" }}>
                                  <i>
                                    {" "}
                                    -{" "}
                                    {format(
                                      new Date(item.createdAt),
                                      "MMM dd, yyyy"
                                    )}
                                  </i>
                                </h5>
                              </div>
                            </div>
                            <div className="review">
                              <p>{item.review}</p>
                            </div>
                          </div>
                        </div>
                      ))
                      : ""}
                    {visibleItems < props?.review?.length && (
                      <div className="show_moresec">
                        <button onClick={handleViewMore} className="show_more">
                          View More
                        </button>
                      </div>
                    )}
                    <div className="enter-review">
                      <h5>ADD A REVIEW</h5>
                      <p>
                        Your email address will not be published. Required fields
                        are marked
                      </p>
                      <form onSubmit={handleSubmit} ref={form}>
                        <div className="d-flex align-items-center gap-2 mb-3">
                          <div>
                            <p>Your rating :</p>
                          </div>
                          <div class="rating">
                            <label>
                              <input
                                onChange={onChangeRating}
                                type="radio"
                                name="stars"
                                value="1"
                              />
                              <span class="icon">★</span>
                            </label>
                            <label>
                              <input
                                onChange={onChangeRating}
                                type="radio"
                                name="stars"
                                value="2"
                              />
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                            </label>
                            <label>
                              <input
                                onChange={onChangeRating}
                                type="radio"
                                name="stars"
                                value="3"
                              />
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                            </label>
                            <label>
                              <input
                                onChange={onChangeRating}
                                type="radio"
                                name="stars"
                                value="4"
                              />
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                            </label>
                            <label>
                              <input
                                onChange={onChangeRating}
                                type="radio"
                                name="stars"
                                value="5"
                              />
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                              <span class="icon">★</span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex gap-2  new_field_review">
                          <div className="review-feald">
                            <label for="review">Your review :</label>
                          </div>
                          <div className="flex-grow-1">
                            <textarea
                              className="review w-100"
                              requiired
                              onChange={onChangeDescription}
                              id="review"
                            ></textarea>
                            <div className="d-flex gap-3 user-detail">
                              <div className="flex-grow-1">
                                <label for="name">Name</label>
                                <input
                                  type="text"
                                  required
                                  className="w-100"
                                  placeholder="Name"
                                  onChange={onChangeName}
                                  id="name"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <label for="email">Email</label>
                                <input
                                  type="email"
                                  onChange={onChangeEmail}
                                  required
                                  className="w-100"
                                  placeholder="Email"
                                  id="email"
                                />
                              </div>
                            </div>
                            <button
                              className="submit-btn mt-3"
                              disabled={loading}
                            >
                              {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Save</span>
                            </button>
                            {/* <input type='submit' className="submit-btn mt-3" value='Submit' /> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="new_did_shipping">

            {/* {props?.data?.price_groups ? (
              JSON.parse(props?.data?.price_groups).map((item, i) => {
                return (
                  <>
                   
                    <div className="min_quantity_div">
                      <p>The minimum order quantity is {item.min} pieces</p>
                      <div className="row">
                        <div className="col-lg-6">
                          <p>{item.min}</p>
                        </div>
                        <div className="col-lg-6">
                          <p>from <b>$ {item.mrp}</b></p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : ""} */}

            <div className="min_quantity_div">
              {/* <p>The minimum order quantity is {props?.data?.price_groups} pieces</p> */}
              <div className="row">
                <div className="col-lg-6">
                  {/* <p>{props?.data?.price_groups}</p> */}
                </div>
                <div className="col-lg-6">
                  {/* <p>from <b>$ {props?.data?.price_groups}</b></p> */}
                </div>
              </div>
            </div>

            {/* <div className="min_quantity_div">
              <p>The minimum order quantity is {gCart} pieces</p>
              <div className="row">
                <div className="col-lg-6">
                  <p>50-1000</p>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <p>from <b>$ {unitPrice}</b></p>
                </div>
              </div>
            </div> */}
            <div className="min_quantity_div">

              <div className="row">
                <div className="col-lg-6">
                  <p>Tax</p>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <p> <b>{props?.data?.tax_rate} %</b></p>
                </div>
              </div>
            </div>
            <ul>
              {props?.data?.ShippingRates?.map(item => (
                <li key={item.id}>
                  {item.country_id === parseInt(localStorageCountryId, 10) ? (

                    <>
                      <div className="row">
                        <div className="col-lg-6">
                          <p>Shipping </p>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-end">
                          <p> <b>£ {getShippingAmount(item, cartInput)}</b></p>
                        </div>
                      </div>
                    </>
                  ) : (
                    null
                  )}
                </li>
              ))}
            </ul>


            <div className="addTocart_btn">
              <button
                className="add_to_cart_button"
                onClick={() => addTocart(props?.data?.id)}

              >
                Add To Cart
              </button>
            </div>
            {/* <div className="ventore_btn company_profile">
              <Link to={`/vendor-store/${props.data?.Store?.slug}`}>
                Company profile
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div >
  );
}

export default SingleProductSpecs;
