import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotFound from "../../../common/NotFound";
import { toast } from "react-toastify";


const MayLike = (props) => {
  const [cartselect, setCartSelect] = useState("1-99");
  const [cartInput, setCartInput] = useState(1);
  const [gCart, setGCart] = useState(1)
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const addTocart = (e,item) => {
    item.cart_qty = cartInput;
    item.price =
      unitPrice !== "" ? unitPrice : cartInput * item?.offer_price;
    props.addTocart(e, item);
    setCartInput(1)
    setGCart(1)
    setCalculatedPrice(200)
  };
  const addTowhislist = (event, item) => {
    props.addTowhislist(event, item);
  };
  function calculatePrice(cartInput) {
    for (const range of JSON.parse(props?.data?.price_groups)) {
      if (cartInput >= range.min && cartInput <= range.max) {
        return range.mrp;
      }
    }
    return 0;
  }
  const setMainInputValue = (e) => {
    if (e.target.value === "") {
      setCartInput(gCart);
    }
    else if (e.target.value < gCart) {
      toast.error(`Cart value should not be less than ${gCart}`)
      setCartInput(parseInt(gCart))
    }
    else {
      setCartInput(Number.parseInt(e.target.value));
      const numericValue = parseFloat(e.target.value);
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * e.target.value;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setUnitPrice(calculatePrice(numericValue));
          setCalculatedPrice(price * e.target.value);
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  const increaseNumber = () => {
    setCartInput(cartInput + 1);
    let x = Number.parseInt(document.getElementById("cart_input").value) + 1;
    const numericValue = x;
    if (!props?.data?.price_groups) {
      let unitP = props?.data?.offer_price * x;
      setCalculatedPrice(unitP);
      setUnitPrice(props?.data?.offer_price);
    } else {
      if (!isNaN(numericValue)) {
        const price = calculatePrice(numericValue);
        setCalculatedPrice(price * x);
        setUnitPrice(calculatePrice(numericValue));
      } else {
        setCalculatedPrice(0);
      }
    }
  };
  const decreaseNumber = () => {
    if (cartInput === 1 || cartInput === gCart) {
      toast.error(`Value should not less than ${gCart} !!`);
    } else {
      setCartInput(cartInput - 1);
      let x = Number.parseInt(document.getElementById("cart_input").value) - 1;
      const numericValue = x;
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * x;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setCalculatedPrice(price * x);
          setUnitPrice(calculatePrice(numericValue));
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  return (
    <div className="recommend my-5">
      <h3>You may also like </h3>
      <div className="container">
        <div className="row">
          {props.data && props.data.length > 0 ? (
            props.data.slice(0, 6).map((item, key) => (
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 card_inner">
                <div className="card_inner_body">
                  <div className="img_sec img_cards">
                    <Link
                      to={
                        "/" +
                        item.mastercategory.slug +
                        "/" +
                        item.category.slug +
                        "/" +
                        item.subcategory.slug +
                        "/" +
                        item.slug
                      }
                    >
                      <img
                        src={
                          item.file_path
                            ? "https://api.dealitt.com/" + item.file_path
                            : ""
                        }
                        class="w-100"
                        alt=""
                        srcset=""
                      />
                    </Link>
                    {item?.Carts &&
                    item?.Carts.filter(function (value) {
                      return value.product_id === item.id;
                    }).length > 0 ? (
                      <div class="wishlist-icon">
                        <input
                          type="checkbox"
                          className="remove-cart"
                          key={item.id}
                          checked
                          onClick={(e) => addTowhislist(e, item)}
                        />
                        <span className="icon-wishlist"></span>
                      </div>
                    ) : (
                      <div class="wishlist-icon">
                        <input
                          type="checkbox"
                          key={item.id}
                          onClick={(e) => addTowhislist(e, item)}
                        />
                        <span className="icon-wishlist"></span>
                      </div>
                    )}
                  </div>
                  <div className="card_content_price text-center card_content">
                    <Link
                      to={
                        "/" +
                        item.mastercategory.slug +
                        "/" +
                        item.category.slug +
                        "/" +
                        item.subcategory.slug +
                        "/" +
                        item.slug
                      }
                    >
                     <h4>{item.name.length > 15 ? item.name.substring(0,15) + "..." : item.name}</h4>
                    </Link>
                  </div>
                  <div className="card_content_price text-center card_content">
                    <p>£ {item.cost_price}</p>
                  </div>
                  {/* <div className="card_content_info text-center card_content">
                    <p>200 pieces</p>
                  </div> */}
                  <div className="add_to_cart">
                    <button
                      className="main_button"
                      onClick={(e) => addTocart(e, item)}
                      // data-bs-toggle="modal"
                      // data-bs-target={`#staticBackdrop_${item?.id}`}
                  //     onClick={() => {
                  //   if (item?.price_groups) {
                  //     let cartMinVal = JSON.parse(item?.price_groups);
                  //     setCartInput(Number(cartMinVal[0]?.min));
                  //     setGCart(Number(cartMinVal[0]?.min))
                  //   }
                  // }
                  // }
                    >
                      Add To Cart
                    </button>
                    <div className="">
                    <div
                      class="modal fade"
                      id={`staticBackdrop_${item?.id}`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdrop2Label"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-body py-3">
                            <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                              <img
                                src={
                                  item?.file_path
                                    ? "https://api.dealitt.com/" +
                                      item?.file_path
                                    : ""
                                }
                                class="d-block w-100"
                                alt="..."
                              />
                              <h5 className="mt-4">{item?.name}</h5>
                              <div className="int_sec">
                                {/* <button
                                  className="int_dec"
                                  onClick={decreaseNumber}
                                >
                                  -
                                </button> */}
                                <input
                                  type="number"
                                  id="cart_input"
                                  className="form-control"
                                  value={cartInput}
                                  onChange={setMainInputValue}
                                  readOnly
                                />

                                {/* <button
                                  className="int_inc"
                                  onClick={increaseNumber}
                                >
                                  +
                                </button> */}
                              </div>
                              {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                              <div className="d-flex justify-content-center mt-3">
                                <button
                                  type="button"
                                  class="main_button me-3"
                                  data-bs-dismiss="modal"
                                  onClick={(e) => addTocart(e,item)}
                                >
                                  Done
                                </button>
                                <button
                                  type="button"
                                  class="main_button red_button"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                  setCartInput(1);
                                  setGCart(1)
                                }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default MayLike;
