import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import '../customCss/VendorStore.css'
import DataService from '../services/data.service'
import AuthService from '../services/auth.service'
import cate_prdt1 from "../images/VendorImages/cate-prdt1.png"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faBars, faPhoneAlt, faGem, faCircle, faSignal, faMedal, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import Proimg from "../../view/images/pro-img.jpg"

function VendorContact(props) {
    const form = React.useRef();
    const params = useParams();
    const [product_qty, setProduct_qty] = useState("");
    const [product_unit, setProduct_unit] = useState(props?.data?.unit);
    const [message, setMessage] = useState("");
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [company, setCompany] = useState([])
    const [showFormChat, setShowFormChat] = useState(false);
    const [VendorChat, setVendorChat] = useState('');
    const [products, setProducts] = useState([])

    const getData = async () => {

        await DataService.getCompanyProfile(params.slug).then((data) => {
            setCompany(data.data.data);
        });
        await DataService.getVendorProducts(params.slug).then((data) => {
            setProducts(data.data.data);
        });
    };
    useEffect(() => {
        getData();
    }, [])




    const sendNewMessage = (e) => {
        e.preventDefault();
        const data = {
            to_user_id: company.id,
            message,
            product_qty,
            product_unit,
        };
        DataService.addChatWithVendor(data).then(
            () => {

                toast.success("message Sent!!");
                setShowFormChat(false)
                navigate("/chats")
                // getChat();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    };



    const getChat = async () => {
        await DataService.getChatWithVendor(company?.id)
            .then(async (data) => {
                setVendorChat(data?.data?.data)
                if (data?.data?.data === null) {
                    setShowFormChat(true)

                } else {
                    navigate("/chats");
                    setShowFormChat(false)
                }
            })
            .catch((error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            });
    };


    const navigate = useNavigate();
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerPadding: "40px",
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };
    const navigatePage = (e, slug) => {
        navigate("/" + slug);
    };
    return (
        <>
            <Header />
            <div className='main_vendor_name'>
                <div className='container'>
                    <div className='vendor_inner'>
                        <div className='company_image'>
                            <img
                                src={
                                    company.logo_path
                                        ? "https://api.dealitt.com/" + company.logo_path
                                        : ""
                                }
                                alt="Slider-Img"
                            />
                        </div>
                        <div className='company_discrip'>
                            <h1>{company.name}</h1>
                            <p>Main Products : {company.main_products}</p>
                            <p>Total Staff : {company.office_size}</p>
                            <p className='new_bg_vendor'>Register Year : {company.register_year}</p>
                        </div>



                        <div className="btn_vendor">
                            <button onClick={getChat}  >Chat Now</button>
                        </div>


                        {showFormChat && (

                            <div className='main_popvendor'>

                                <div className="capacity-form-newvendor">
                                    <div className='cut_vendor_chat'>
                                        <button onClick={() => setShowFormChat(false)}>X</button>
                                    </div>
                                    <div className="form-head mb-3">
                                        <h2>Send your message to this supplier</h2>
                                    </div>
                                    <form onSubmit={sendNewMessage} ref={form}>
                                        <div className="row align-items-baseline capacity-row">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>To:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-10 p-md-0">
                                                <div className="capacity-field">
                                                    <h3>{VendorChat?.legal_owner}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Message:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field">
                                                    <textarea
                                                        rows="6"
                                                        placeholder="Enter your inquiry details such as product name,color,size,MOQ,FOB,etc."
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    ></textarea>
                                                    <div className="capacity-info">
                                                        <p>
                                                            Your message must be between 20-8000 characters
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Quantity:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field-main d-md-flex">
                                                    <div className="capacity-field pe-2">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Please enter a number"
                                                            onChange={(e) => setProduct_qty(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="capacity-select">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setProduct_unit(e.target.value)}
                                                            value={props?.data?.unit}
                                                        >
                                                            <option value={props?.data?.unit}>
                                                                {props?.data?.unit}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="capacity-btn">
                                                    <button type="submit" className="btn">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        )}











                    </div>
                </div>
            </div>
            {/* navbar start */}
            <section className="vendor-header-section">
                <div className="container">

                    <nav className="navbar navbar-expand-lg p-0">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={`/vendor-store/${company.slug}`}>Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Products
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                        {
                                            products && products.length > 0 ? (products?.slice(0, 6).map((item) => {
                                                return (
                                                    <>
                                                        <li><Link className="dropdown-item" to="#">{item.name}</Link></li>

                                                    </>
                                                )
                                            })) : ("")


                                        }

                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Profile
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                        <li><Link className="dropdown-item" to={`/vendor-profile/${company.slug}`}>Company Overview</Link></li>
                                        <li><Link className="dropdown-item" to="#">Rating & Reviews</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " aria-current="page" to={`/vendor-contact/${company.slug}`}>Contact</Link>
                                </li>


                            </ul>
                            {/* <form role="search" className="pb-3 pb-lg-0">
                    <input className="form-control me-2" type="search" placeholder="Search in this store" aria-label="Search"/>
                </form> */}
                        </div>
                    </nav>

                </div>
            </section>
            {/* navbar end */}



            {/* <!--############################### BANNER-SECTION-START ###############################--> */}

            <section className="marquee-section pt-5">


            </section>
            {/* <!--############################### MARQUEE-SECTION-END ###############################-->

<!--############################### CATEGORY-SECTION-START ###############################--> */}
            <div className='container'>
                <div className='contact_main_vendor'>

                    <div className='contact_vendor'>
                        <div className='contact_vendor_first'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h2 className='performance_head'>Performance</h2>
                                    <div className='inner_divcontact'>
                                        <div className='inner_divrating'>
                                            <h3>5.0 <span>/5</span></h3>
                                        </div>
                                        <div className='inner_divratingtwo'>
                                            <p>Very satisfied</p>
                                            <p>99 reviews</p>
                                        </div>
                                    </div>
                                    <div className='inner_divtime'>
                                        <p><b>≤3h</b>  average response time</p>
                                        <p><b>100.0%</b>  on-time delivery rate</p>
                                        <p><b>£ 740,000+</b>  92 orders</p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    {company?.video_path ? (
                                        <>
                                            <video width="100%" className="mt-lg-5 mt-3" height="300" controls autoplay >
                                                <source
                                                    src={
                                                        company?.video_path
                                                            ? "https://api.dealitt.com/" + company?.video_path
                                                            : ""
                                                    }
                                                    type="video/mp4"
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* <video width="320" height="240" controls autoplay>
                                        <source src={
                                            company?.video_path ? "https://api.dealitt.com/" +  company?.video_path : ""
                                        } type="video/mp4"/>
                                           </video> */}
                                </div>

                            </div>
                        </div>

                        <div className='company_location'>
                            <h2>Main Products</h2>
                            <div className='row'>
                                {
                                    products && products.length > 0 ? (products?.slice(0, 4).map((pro) => {
                                        return (
                                            <>
                                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 card_inner">
                                                    <div className="card_inner_body">
                                                        <div className="img_sec_new img_cards">
                                                            <Link
                                                                to={
                                                                    "/" +
                                                                    pro.mastercategory.slug +
                                                                    "/" +
                                                                    pro.category.slug +
                                                                    "/" +
                                                                    pro.subcategory.slug +
                                                                    "/" +
                                                                    pro.slug
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        pro.file_path
                                                                            ? "https://api.dealitt.com/" + pro.file_path
                                                                            : ""
                                                                    }
                                                                    class="d-block w-100"
                                                                    alt="..."
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="card_content_price text-center card_content">
                                                            <Link
                                                                to={
                                                                    "/" +
                                                                    pro.mastercategory.slug +
                                                                    "/" +
                                                                    pro.category.slug +
                                                                    "/" +
                                                                    pro.subcategory.slug +
                                                                    "/" +
                                                                    pro.slug
                                                                }
                                                            >
                                                                <h4>{pro.name}</h4>
                                                            </Link>
                                                        </div>
                                                        <div className="card_content_price text-center card_content">
                                                            <p>£ {pro.offer_price}</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })) : ("")

                                }
                            </div>

                        </div>
                        {/* <div className='company_location'>
                            <div className='company_overview_tab'>

                                <Link to="#">Profile</Link>
                                <Link to="#">Reviews</Link>
                                <Link to="#">Videos tips</Link>
                                <Link to="#">Products</Link>
                            </div>
                        </div> */}

                        <div className='company_location'>
                            <div className='company_overview'>
                                <div className='company_details_profile'>
                                    <h2>Profile</h2>

                                    <div className='row'>
                                        <div className='col-lg-4 div_details_company'>
                                            <h3>Overview</h3>
                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Company registration date <span>{company?.register_year}</span></p>
                                            <p>Accepted languages<span>{company?.language_spoken ? JSON.parse(company.language_spoken).join(', ') : ''}</span></p>
                                            <p>Years in industry <span>13</span></p>

                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p> Operation city<span>{company?.operation_city}</span></p>
                                            <p>Years exporting<span>{company?.register_year}</span></p>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-4 div_details_company'>
                                            <h3>Certifications</h3>
                                        </div>
                                        <div className='col-lg-8'>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-4 div_details_company'>
                                            <h3>Production capabilities</h3>
                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Production lines<span>{company?.production_lines_count}</span></p>

                                            <p>Production machines<span>production_equipment</span></p>


                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Total annual output (units) <span>2000</span></p>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4 div_details_company'>
                                            <h3>Quality control</h3>
                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Product Support traceability of raw materials <span>2000</span></p>
                                            <p>Quality control conducted on all production lines<span>2000</span></p>


                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Product inspection method<span>2000</span></p>
                                            <p>QA/QC inspectors <span>{company?.qc_staff}</span></p>


                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4 div_details_company'>
                                            <h3>R&D capabilities</h3>
                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Customization options<span>2000</span></p>

                                            <p>R&D engineers<span>2000</span></p>


                                        </div>
                                        <div className='col-lg-4 div_details_company'>
                                            <p>Rd staff<span>{company?.rd_staff}</span></p>



                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>


                    <div className='vendor_chat'>
                        <h2>Contact supplier</h2>
                        <div className='company_name'>
                            <p>{company?.name}</p>
                        </div>
                        <div className='btns_two'>
                            <button onClick={getChat}>Chat now</button>
                        </div>
                        <div className='btn_inquiry'>
                            <button>Send inquiry</button>
                        </div>

                        
                        <div className='call_us'>
                            <button><FontAwesomeIcon icon={faPhoneAlt} flip="horizontal" /> Call us</button>
                        </div>
                    </div>
                </div>


            </div>



            <Footer />
        </>
    )
}

export default VendorContact
